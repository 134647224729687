<template>
    <div>
        <Panel module="Treinamento" layout="large" :showFilter="false" :showSearch="false" :showButtons="false">
            <div slot="content-main">
                <div class="text-center">
                    <br />
                    <ButtonFilter :options="[
                        {
                            title: 'Editavel',
                            classIcon: 'fa-solid fa-pen-to-square',
                            value: 'edit',
                        },
                        {
                            title: 'Cliente',
                            classIcon: 'fa-solid fa-eye',
                            value: 'view',
                        },

                    ]" initialOption="edit" v-model="selectType" />
                </div>
                <br>
                <Course v-if="selectType == 'edit'" />
                <CourseView v-else />
            </div>
        </Panel>
    </div>
</template>
  
<script>


import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import ButtonFilter from "@nixweb/nixloc-ui/src/component/forms/ButtonFilter";

import Course from '@nixweb/nixloc-ui/src/component/training/Course.vue'
import CourseView from '@nixweb/nixloc-ui/src/component/training/CourseView.vue'

import { mapMutations } from "vuex";

export default {
    name: "CourseListView",
    components: {
        Panel, Course, ButtonFilter, CourseView
    },
    data() {
        return {
            panel: {
                module: "Suporte",
                title: "Treinamento",
                formName: "courseCreateUpdate",
                showFilter: false,
                showSearch: false,
                showButtons: false,
            },
            selectType: "edit",
        };
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    methods: {
        ...mapMutations("generic", ["removeLoading"]),
    },
};
</script>
  