var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Panel',{attrs:{"module":"Treinamento","layout":"large","showFilter":false,"showSearch":false,"showButtons":false}},[_c('div',{attrs:{"slot":"content-main"},slot:"content-main"},[_c('div',{staticClass:"text-center"},[_c('br'),_c('ButtonFilter',{attrs:{"options":[
                    {
                        title: 'Editavel',
                        classIcon: 'fa-solid fa-pen-to-square',
                        value: 'edit',
                    },
                    {
                        title: 'Cliente',
                        classIcon: 'fa-solid fa-eye',
                        value: 'view',
                    } ],"initialOption":"edit"},model:{value:(_vm.selectType),callback:function ($$v) {_vm.selectType=$$v},expression:"selectType"}})],1),_c('br'),(_vm.selectType == 'edit')?_c('Course'):_c('CourseView')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }